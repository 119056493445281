<template>
    <div>
        <Editor url="/backend/gruppo/" :fields="fields" :filters="filters" icon="mdi-account-group"
                testo-list="Lista gruppi"
                testo-insert="Nuovo gruppo"
                testo-update="Modifica gruppo"
                testo-delete="Vuoi cancellare il gruppo?">
            <template #form="{instance, errors}">
                <v-text-field :error-messages="errors.nome" v-model="instance.nome"
                              label="Nome"></v-text-field>
                <Relation v-model="instance.users" url="/backend/autocomplete/userprofile"
                          label="Utenti" multiple></Relation>
            </template>
            <template #item.users="{item}">
                {{ item.users.length }}
            </template>
        </Editor>
    </div>
</template>


<script>
import Editor from "@/components/Editor";
import Relation from "@/components/Relation";

export default {
    data: () => ({
        fields: [
            {text: 'Nome', value: 'nome'},
            {text: 'Utenti', value: 'users'},
        ],
    }),
    computed: {
        filters() {
            return [
                {label: 'Nome', field: 'nome'},
            ];
        },
    },
    methods: {},
    components: {
        Relation,
        Editor
    },
}
</script>